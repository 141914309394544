import React from 'react'
import { Container, Row, Col, Hidden } from 'react-grid-system'
import ReactMarkdown from 'react-markdown'

export default function TwoCol(props) {

    return (
        <div className={`two-col py-28 ${props.white ? '' : 'bg-green-500'}`}>
            <Container>
                <Row>
                    <Hidden xs sm>
                        <Col md={1}></Col>
                    </Hidden>
                    <Col md={5} sm={12}>
                        <div className={`col-section ${props.checkmark ? 'checkmarks' : ''}`}>
                            <ReactMarkdown children={props.column1} className={props.white ? '' : 'text-white'} />
                        </div>
                    </Col>
                    <Col md={5} sm={12}>
                        <div className={`col-section ${props.checkmark ? 'checkmarks' : ''}`}>
                            <ReactMarkdown children={props.column2} className={props.white ? '' : 'text-white'} />
                        </div>
                    </Col>
                    <Hidden xs sm>
                        <Col md={1}></Col>
                    </Hidden>
                </Row>
            </Container>    
        </div>
    )
}
