import React from 'react'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from '../components/rows/hero'
import Intro from '../components/rows/intro'
import ImgSection from '../components/rows/imgsection'
import Outro from '../components/rows/outro'
import TwoCol from '../components/rows/twocol'

import Banner from '../images/img/hero/invasive-species-hero.jpg'

export default function InvasiveSpecies({data}) { // <== Make sure data is sent to the function

    return (
        <Layout>
        <Seo title="Invasive Species" />
        <Hero 
            title="Eliminating Invasive Species"
            banner={Banner}
        />
        {data.allStrapiInvasiveSpecies.edges.map(({ node }) => ( // <== Map out the data from the node.
            <> 
            <div className="intro py-56">
              <Intro key={node.species_intro.id}
                    title={node.species_intro.title}
                    subtitle={node.species_intro.subtitle}
                    description={node.species_intro.description}
                />
                <TwoCol 
                    white
                    key={node.species_twocol.id}
                    column1={node.species_twocol.description_1}
                    column2={node.species_twocol.description_2}
                />
            </div>
                <ImgSection key={node.species_img.id}
                    buttonOrange
                    subtitle={node.species_img.subtitle}
                    title={node.species_img.title}
                    description={node.species_img.description}      
                    image={node.species_img.image.localFile.publicURL}
                    link={node.species_img.button_title}
                    href={node.species_img.button_link}
                />
                <Outro key={node.species_outro.id}
                    subtitle={node.species_outro.subtitle}
                    title={node.species_outro.title}
                    link={node.species_outro.button_title}
                    href={node.species_outro.button_link}
                />
            </>
        ))}            
        </Layout>    
    )
}


export const query = graphql`
query InvasiveSpecies {
    allStrapiInvasiveSpecies {
      edges {
        node {
          species_intro {
            id
            subtitle
            title
          }
          species_twocol {
            id
            description_1
            description_2
          }
          species_img {
            id
            subtitle
            title
            description
            image {
              localFile {
                publicURL
              }
            }
          }
          species_outro {
            id
            subtitle
            title
            button_title
            button_link
          }
        }
      }
    }
  }
`